<template>
  <div :class="{ 'isRendered': isRendered }">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="serverCapacityChartSeries"
      @updated="onChartUpdated"
    />
  </div>
</template>

<script>
import moment from '@/libs/moment'
import AvamarChartMixin from './AvamarChartMixin'

export default {
  mixins: [AvamarChartMixin],
  data() {
    return {
      isRendered: false,
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'serverCapacity',
          type: 'line',
          group: this.$props.chartGroup,
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: 'Server Capacity',
          align: 'left',
        },
        yaxis: {
          title: {
            text: 'Capacity',
          },
          labels: {
            formatter(val) {
              return `${(val).toFixed(2)} TB`
            },
          },
          minWidth: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          y: {
          // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val !== null) {
                return `${(val).toFixed(2)} TB`
              }
              return null
            },
          },
          x: {
            // eslint-disable-next-line no-unused-vars
            formatter(val) {
              return moment.utc(val).format('L LT')
            },
          },
        },
      }
    },
    serverCapacityChartSeries() {
      return [
        {
          name: 'Capacity used',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.used / 1024 / 1024 / 1024 / 1024]),
        },
        {
          name: 'Total capacity',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.capacity / 1024 / 1024 / 1024 / 1024]),
        },
        {
          name: 'Bytes protected',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.bytesProtected / 1024 / 1024 / 1024 / 1024]),
        },
      ]
    },
  },
}

</script>
