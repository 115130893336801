<template>
  <div>
    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last7Days', 'last30Days', 'last3Months', 'last6Months', 'thisYear', 'last2Years', 'last3Years', 'last5Years']"
            :timerange="asup.timerange"
          />
        </b-form-group>
      </b-form>
    </b-card>

    <b-overlay :show="isLoading">
      <b-row>
        <b-col cols="6">
          <b-card>
            <ServerCapacityChartView
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :asup="asup"
            />
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card>
            <ServerUtilizationChartView
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :asup="asup"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, VBPopover, BForm, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import AvamarService from '@/service/avamar.service'
import moment from '@/libs/moment'

import ServerCapacityChartView from './charts/ServerCapacityChartView.vue'
import ServerUtilizationChartView from './charts/ServerUtilizationChartView.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BOverlay,
    DateRangePicker,
    ServerCapacityChartView,
    ServerUtilizationChartView,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'last6Months',
      },
      isLoading: false,
      stats: [],
      statisticDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
  },
  beforeMount() {
    this.$watch('timeRange', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      AvamarService.getAllStatisticListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
      }, { disableTenantFilter: true })
        .then(result => {
          this.stats = result
          this.statisticDataLoaded = true
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
