<template>
  <div :class="{ 'isRendered': isRendered }">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="serverUtilizationChartSeries"
      @updated="onChartUpdated"
    />
  </div>
</template>

<script>
import { $themeColors } from '@themeConfig'
import moment from '@/libs/moment'
import AvamarChartMixin from './AvamarChartMixin'

export default {
  mixins: [AvamarChartMixin],
  data() {
    return {
      isRendered: false,
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'serverUtilization',
          type: 'line',
          group: this.$props.chartGroup,
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        annotations: {
          yaxis: [
            {
              y: 80,
              y2: 95,
              fillColor: $themeColors.warning,
            },
            {
              y: 95,
              y2: 100,
              fillColor: $themeColors.danger,
            },
          ],
        },
        markers: {
          size: 0,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: 'Server Utilization',
          align: 'left',
        },
        yaxis: {
          title: {
            text: 'Server Utilization',
          },
          labels: {
            formatter(val) {
              return `${(val).toFixed(0)} %`
            },
          },
          min: 0,
          max: 100,
          minWidth: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          y: {
          // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val !== null) {
                return `${(val).toFixed(2)} %`
              }
              return null
            },
          },
          x: {
            formatter(val) {
              return moment.utc(val).format('L LT')
            },
          },
        },
      }
    },
    serverUtilizationChartSeries() {
      // Adjust UTC timestamp by timezone offset so that the chart shows dateTimes from the local timezone of avamar system
      return [
        {
          name: 'Server Utilization',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.serverUtilization]),
        },
      ]
    },
  },
}

</script>
